:root {
  --lp-space-xs: 16px;
  --neutral-600: #3C3C3B;
  --neutral-800: #191E33;
  --lp-colors-error: #c71f3a;
  --toastify-color-progress-success: #BFE0D2;
  --toastify-color-progress-error: #B32D3F;
  --toastify-color-progress-error2: #D3354A;
  --toastify-color-progress-error3: #FDF5F6;
}

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
html,
body {
  overscroll-behavior-y: none !important;
}
code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}
body {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  min-height: 100vh;
  -ms-box-orient: vertical;
  box-orient: vertical;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
}
body,
html {
  margin: 0;
  padding: 0;
  width: 100%;
  height: 100%;
  background: #fff none repeat scroll 0 0;
}
body {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -ms-box-orient: vertical;
  box-orient: vertical;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  height: 100%; /* 1, 3 */
}
svg:not(:root) {
  overflow: hidden;
}
#root {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  min-height: 100%;
  -ms-box-orient: vertical;
  box-orient: vertical;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-align-items: stretch;
  -webkit-box-align: stretch;
  -ms-flex-align: stretch;
  align-items: stretch;
}

/* Maximum height */
@media (max-height: 450px) {
  body,
  #root {
    min-height: inherit;
  }
}

.lp-align-center {
  align-items: center;
}
.mr-6 {
  margin-right: 6px !important;
}

/* Modal Overwrides */
.modal-small {
  width: 100%;
  max-width: 370px;
}
.modal-medium {
  width: 100%;
  max-width: 550px;
  overflow: hidden !important;
}
/* .modal-small,
.modal-medium {
  max-height: 700px !important;
} */
.modal-content {
  overflow-y: auto;
  max-height: calc(100vh - 240px) !important;
}
@media (max-width: 767px) {
  .modal-content {
    max-height: calc(100vh - 280px) !important;
  }
}

/* Minimum height */
@media (min-height: 768px) {
  .modal-content {
    max-height: calc(100vh - 250px) !important;
  }
}

.modal-small,
.modal-medium {
  margin-left: auto;
  margin-right: auto;
  margin-top: 20px !important;
  margin-bottom: 20px !important;
  /* inset: 25px !important; */
}
@media (max-width: 767px) {
  body {
    overflow-x: hidden !important;
  }
  .modal-small,
  .modal-medium {
    width: calc(100% - 25px);
    max-width: 350px !important;
    /* inset: 15px !important; */
    /* max-height: calc(100vh - 40px) !important; */
  }
  .modal-main {
    padding: var(--lp-space-sm) !important;
  }
  .page-title {
    font-size: var(--lp-font-size-heading-sm) !important;
  }
  .modal-main .modal-content {
    overflow-x: hidden !important;
  }
  .modal-footer {
    margin-top: 0 !important;
  }
}
.ReactModal__Overlay {
  background-color: rgba(var(--lp-colors-neutral-900), 0.3) !important;
}

/* Form Disabled */
.form-disabled {
  position: relative;
}
.form-disabled, .form-disabled * {
  color: #B3B8CC !important;
  cursor: not-allowed !important;
}
.row-disabled .MuiInput-underline.Mui-disabled::before {
  border-bottom-style: solid;
}

.text-wrap {
  word-wrap: anywhere !important;
  /* word-break: break-all !important; */
}
.link-underline {
  text-decoration: underline !important;
}
.text-primary {
  color: var(--neutral-800) !important;
}

/* Custom Country Select */
.custom-select {
  width: 100%;
  display: flex;
  position: relative;
  align-items: center;
}
.custom-select .MuiFormControl-root label {
  left: 50px !important;
}
.custom-select .MuiInputBase-root {
  padding-left: 44px !important;
}
.country-block {
  left: 16px;
  width: 24px;
  max-width: 24px;
  flex-shrink: 0;
  height: 24px;
  display: flex;
  position: absolute;
  align-items: center;
  justify-content: center;
}
.country-block-img {
  width: 24px;
  max-width: 24px;
}
.w100-24 {
  width: calc(100% - 24px);
}
.MuiInputBase-root.Mui-error .MuiInputBase-input {
  padding-left: 0 !important;
}
.modal-content .MuiInputBase-root.Mui-error .MuiInputBase-input {
  margin-left: 0 !important;
  /*margin-left: -14px !important;*/
}
.modal-content .lp-equal-width .MuiInputBase-root.Mui-error .MuiInputBase-input {
  margin-left: 0 !important;
  padding-left: 16px !important;
}

.capture-payment-modal .lp-equal-width .MuiInputAdornment-positionStart svg {
  top: 3px;
  position: relative;
}

/* Custom Upload Button */
.btn-file {
  height: 40px;
  overflow: hidden;
  position: relative;
  box-sizing: border-box;
  cursor: pointer;
  font-family: Geograph, san-serif;
  background: rgba(var(--lp-colors-neutral-50), 1);
  color: rgba(var(--lp-colors-primary), 1);
  font-size: var(--lp-font-size-button-md);
  font-weight: var(--lp-font-weight-button-md);
  line-height: var(--lp-line-height-button-md);
  letter-spacing: var(--lp-letter-spacing-button-md);
  padding: 0 var(--lp-space-xs);
  border-radius: var(--lp-radii-sm);
}
.btn-file input[type=file] {
  top: 0;
  right: 0;
  font-size: 0px;
  min-width: 100%;
  min-height: 100%;
  text-align: center;
  filter: alpha(opacity=0);
  opacity: 0;
  outline: none;
  cursor: inherit;
  display: block;
  position: absolute;
  background: transparent;
}

@media (min-width: 992px) {
  /* .ReactModal__Content {
    max-height: 700px !important;
  }
  .modal-small,
  .modal-medium {
    inset: 25px !important;
  } */
  .modal-small, .modal-medium,
  .modal-small.hAuto, .modal-medium.hAuto {
    inset: unset !important;
  }
  .modal-medium .modal-content {
    overflow-y: auto !important;
  }
}
.modal-medium .modal-footer button,
.modal-small .modal-footer button {
  margin-left: 16px;
}
.modal-footer {
  padding-top: 28px;
}
.modal-footer .w-70 {
  max-width: 70px;
}
.update-pass-modal .MuiFormControl-root .MuiInputAdornment-positionStart > svg.text-primary {
  fill: var(--neutral-800) !important;
}
/* .MuiFormControl-root .MuiInputLabel-formControl,
.MuiFormControl-root .MuiInputBase-input,
.MuiFormControl-root .MuiFormHelperText-root {
  padding-left: var(--lp-space-xs);
}
.MuiFormControl-root .MuiFormHelperText-root {
  padding-right : var(--lp-space-xs);
}
label.MuiFormLabel-filled {
  padding-left: 22px !important;
} */

/* Phone Input */
.PhoneInput {
  z-index: 2;
  padding: 0 16px;
  position: relative;
}
.PhoneInput::before,
.PhoneInput::after {
  left: 0;
  right: 0;
  bottom: 0;
  content: "";
  position: absolute;
  -webkit-transition: border-bottom-color 200ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
  -moz-transition: border-bottom-color 200ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
  transition: border-bottom-color 200ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
  border-bottom: 1px solid #e0e1ea;
  pointer-events: none;
}
.PhoneInput:hover::before {
  border-bottom-width: 2px;
}
.PhoneInput::after {
  transform: scaleX(0);
  -webkit-transition: transform 200ms cubic-bezier(0.0, 0, 0.2, 1) 0ms;
  -moz-transition: transform 200ms cubic-bezier(0.0, 0, 0.2, 1) 0ms;
  transition: transform 200ms cubic-bezier(0.0, 0, 0.2, 1) 0ms;
  border-bottom: 2px solid #7945e0;
  pointer-events: none;
}
.PhoneInput.PhoneInput--focus::after {
  transform: scaleX(100%);
}
.PhoneInput .PhoneInputCountry {
  margin-right: 0;
}
.PhoneInput .PhoneInputCountry .PhoneInputCountryIcon--border {
  border: 0 none !important;
  box-shadow: none !important;
}
.PhoneInputCountrySelectArrow {
  opacity: 1 !important;
  width: 6px !important;
  height: 6px !important;
  margin-left: 12px !important;
  border-right-width: 2px !important;
  border-bottom-width: 2px !important;
  border-color: var(--neutral-600) !important;
}
.PhoneInputInput {
  font-size: 1rem;
  font-weight: 400;
  padding: 20px 16px;
  outline: none !important;
  color: rgb(85, 92, 124);
  font-family: Geograph, san-serif;
  background-color: transparent !important;
}
.form-floating {
  position: relative;
}
.form-floating > label {
  top: 5px;
  left: 60px;
  height: 100%;
  font-size: 1rem;
  font-weight: 400;
  position: absolute;
  padding: 1rem .75rem;
  pointer-events: none;
  border: 1px solid transparent;
  transform-origin: 0 0;
  -webkit-transition: opacity .1s ease-in-out,transform .1s ease-in-out;
  -moz-transition: opacity .1s ease-in-out,transform .1s ease-in-out;
  transition: opacity .1s ease-in-out,transform .1s ease-in-out;
  color: rgb(85, 92, 124);
  font-family: Geograph, san-serif;
}
.form-floating .PhoneInput--focus~label,
.form-floating > .PhoneInput--focus:not(:placeholder-shown)~label {
  color: rgb(3, 3, 5);
  transform: scale(.75) translateY(-.825rem) translateX(.075rem);
}
.form-floating > .PhoneInput.is-filled~label {
  transform: scale(.75) translateY(-.825rem) translateX(.075rem)
}

.modal-content > .no-pl .Mui-error,
.form-floating.no-pl .PhoneInput {
  padding-left: 0 !important;
}

.w-200 .form-floating {
  width: 100%;
}
.w-200 .form-floating > label {
  top: 0;
  left: 47px;
}
.w-200 .PhoneInput::before,
.w-200 .PhoneInput::after {
  bottom: 5px;
}
.w-200 .PhoneInputInput {
  color: rgb(25, 30, 51);
}

.ReactModal__Overlay {
  z-index: 11;
}

/* Date Range Picker */
.react-date-picker__calendar,
.react-daterange-picker__calendar {
  top: 100% !important;
}
.react-date-picker__calendar .react-calendar,
.react-daterange-picker__calendar .react-calendar {
  width: 100%;
  border: 0 none;
  border-radius: 4px;
  font-family: 'Geograph', san-serif;
  box-shadow: 0px 8px 24px -4px rgba(0, 0, 0, 0.08);
}
.react-daterange-picker__calendar .react-calendar {
  max-width: 328px;
  padding: 16px 20px;
}
.react-calendar__month-view__weekdays__weekday abbr {
  color: #8e93aa;
  font-size: 15px;
  font-weight: 400 !important;
  text-decoration: none !important;
  text-transform: none !important;
}
.react-calendar__navigation button,
.react-calendar__navigation__label,
.react-calendar__tile {
  margin-left: 0 !important;
}
.react-calendar__navigation .react-calendar__navigation__label,
.react-calendar__navigation button.react-calendar__navigation__arrow {
  color: var(--neutral-800);
  height: 28px !important;
  max-height: 28px !important;
}
.react-calendar__navigation button.react-calendar__navigation__arrow {
  width: 28px !important;
  max-width: 28px !important;
  display: flex !important;
  align-items: center !important;
  justify-content: center !important;
}
.react-calendar__month-view__days .react-calendar__tile {
  color: var(--neutral-800);
  width: 42px !important;
  max-width: 42px !important;
  height: 42px !important;
  max-height: 42px !important;
  padding: 10px !important;
  -webkit-transition: all .1s ease-in-out, all .1s ease-in-out;
  -moz-transition: all .1s ease-in-out, all .1s ease-in-out;
  transition: all .1s ease-in-out, all .1s ease-in-out;
}
.react-calendar__year-view button,
.react-calendar__navigation button,
.react-calendar__month-view__days .react-calendar__tile {
  border-radius: 4px;
  -webkit-transition: all .1s ease-in-out, all .1s ease-in-out;
  -moz-transition: all .1s ease-in-out, all .1s ease-in-out;
  transition: all .1s ease-in-out, all .1s ease-in-out;
}
.react-calendar__month-view__days .react-calendar__tile:hover,
.react-calendar__month-view__days .react-calendar__tile:focus,
.react-calendar__month-view__days .react-calendar__tile:active {
  color: var(--neutral-800) !important;
  background-color: #F8F8FB !important;
}
.react-calendar__tile--now,
.react-calendar__tile--hasActive {
  border-radius: 100% !important;
  background-color: #F8F8FB !important;
}
.react-calendar__tile--hasActive.react-calendar__year-view__months__month {
  border-radius: 0px !important;
}
.react-calendar__year-view__months__month {
  font-size: 15px !important;
}
.react-calendar__navigation__label,
.react-calendar__month-view__days__day {
  font-size: 15px;
  color: #191e32;
  line-height: 28px;
  font-weight: 400 !important;
  font-family: 'Geograph', san-serif;
}
.react-calendar__tile--active,
.react-calendar__tile--hasActive,

.react-calendar__month-view__days .react-calendar__tile.react-calendar__tile--active:hover,
.react-calendar__month-view__days .react-calendar__tile.react-calendar__tile--active:focus,
.react-calendar__month-view__days .react-calendar__tile.react-calendar__tile--active:active,

.react-calendar__tile--active:hover,
.react-calendar__tile--active:focus,
.react-calendar__tile--active:active,

.react-calendar__tile--hasActive:hover,
.react-calendar__tile--hasActive:focus,
.react-calendar__tile--hasActive:active {
  color: #fff !important;
  background: #793DE4 !important;
  background-color: #793DE4 !important;
}

.react-calendar__tile--range {
  border-radius: 0 !important;
}
.react-calendar__tile--rangeStart {
  border-top-left-radius: 40px !important;
  border-bottom-left-radius: 40px !important;
}
.react-calendar__tile--rangeEnd {
  border-top-right-radius: 40px !important;
  border-bottom-right-radius: 40px !important;
}

/* Spinner Center Aligned */
.spinner-wrapper {
  display: flex;
  min-height: 100vh;
  align-content: center;
  justify-content: center;
}
.spinner-wrapper > div {
  width: 100%;
  min-height: 100%;
}

/* Input Autofill Colour fixed */
input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:active,
input:-webkit-autofill:focus {
  color: var(--neutral-800) !important;
  background-clip: text !important;
  background-color: linear-gradient(to left, #fff, #fff) !important;
}

/* Balloon Tooltips */
button[data-balloon] {
  overflow: visible;
}

[data-balloon] {
  /* z-index: 1; */
  cursor: pointer;
  border: 0px none;
  position: relative;
  background: transparent;
}

[data-balloon]::before,
[data-balloon]::after {
  left: 50%;
  opacity: 0;
  bottom: 100%;
  position: absolute;
  pointer-events: none;
  z-index: 99999 !important;
  transition: all 0.18s ease-out 0.18s;
  transform: translate(-50%, 10px);
  -webkit-transform-origin: top;
  -ms-transform-origin: top;
  transform-origin: top;
}
[data-balloon]::after {
  color: #fff;
  border-radius: 4px;
  content: attr(data-balloon);
  font-size: 12px;
  line-height: 18px;
  padding: 0.5em 1em;
  white-space: nowrap;
  margin-bottom: 11px;
  text-align: center;
  background: #323750;
  font-weight: 400;
  letter-spacing: 0.35px;
  opacity: 0;
  visibility: hidden;
}
[data-balloon]:hover::after {
  opacity: 1;
  visibility: visible;
}

[data-balloon]:before {
  background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='36' height='12'%3E%3Cpath fill='rgba(50, 55, 80, 1)' d='M2.658 0h32.004c-6 0-11.627 12.002-16.002 12.002C14.285 12.002 8.594 0 2.658 0z'/%3E%3C/svg%3E");
  background-repeat: no-repeat;
  background-size: 100% auto;
  height: 6px;
  width: 18px;
  content: '';
  margin-bottom: 5px;
}

[data-balloon]:hover:before,
[data-balloon][data-balloon-visible]:before,
[data-balloon]:hover:after,
[data-balloon][data-balloon-visible]:after {
  opacity: 1;
  pointer-events: auto;
  transform: translate(-50%, 0);
}

[data-balloon][data-balloon-break]:after {
  white-space: pre-wrap;
}

[data-balloon-pos='down']:before,
[data-balloon-pos='down']:after {
  left: 50%;
  top: 100%;
  bottom: auto;
  transform: translate(-50%, -10px);
}

[data-balloon-pos='down']:after {
  margin-top: 11px;
}

[data-balloon-pos='down']:before {
  background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='36' height='12'%3E%3Cpath fill='rgba(50, 55, 80, 1)' d='M33.342 12H1.338c6 0 11.627-12.002 16.002-12.002C21.715-.002 27.406 12 33.342 12z'/%3E%3C/svg%3E");
  background-repeat: no-repeat;
  background-size: 100% auto;
  height: 6px;
  width: 18px;
  margin-top: 5px;
  margin-bottom: 0;
}

[data-balloon-pos='down']:hover:before,
[data-balloon-pos='down'][data-balloon-visible]:before,
[data-balloon-pos='down']:hover:after,
[data-balloon-pos='down'][data-balloon-visible]:after {
  transform: translate(-50%, 0px);
}

[data-balloon-pos='left']:before,
[data-balloon-pos='left']:after {
  top: 50%;
  left: auto;
  right: 100%;
  bottom: auto;
  transform: translate(10px, -50%);
}

[data-balloon-pos='left']:after {
  margin-right: 11px;
}

[data-balloon-pos='left']:before {
  background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='12' height='36'%3E%3Cpath fill='rgba(50, 55, 80, 1)' d='M0 33.342V1.338c0 6 12.002 11.627 12.002 16.002C12.002 21.715 0 27.406 0 33.342z'/%3E%3C/svg%3E");
  background-repeat: no-repeat;
  background-size: 100% auto;
  height: 18px;
  width: 6px;
  margin-right: 5px;
  margin-bottom: 0;
}

[data-balloon-pos='left']:hover:before,
[data-balloon-pos='left'][data-balloon-visible]:before,
[data-balloon-pos='left']:hover:after,
[data-balloon-pos='left'][data-balloon-visible]:after {
  -webkit-transform: translate(0, -50%);
  -ms-transform: translate(0, -50%);
  transform: translate(0, -50%);
}

[data-balloon-pos='right']:before,
[data-balloon-pos='right']:after {
  bottom: auto;
  left: 100%;
  top: 50%;
  transform: translate(-10px, -50%);
}

[data-balloon-pos='right']:after {
  margin-left: 11px;
}

[data-balloon-pos='right']:before {
  background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='12' height='36'%3E%3Cpath fill='rgba(50, 55, 80, 1)' d='M12 2.658v32.004c0-6-12.002-11.627-12.002-16.002C-.002 14.285 12 8.594 12 2.658z'/%3E%3C/svg%3E");
  background-repeat: no-repeat;
  background-size: 100% auto;
  height: 18px;
  width: 6px;
  margin-bottom: 0;
  margin-left: 5px;
}

[data-balloon-pos='right']:hover:before,
[data-balloon-pos='right'][data-balloon-visible]:before,
[data-balloon-pos='right']:hover:after,
[data-balloon-pos='right'][data-balloon-visible]:after {
  transform: translate(0px, -50%);
}

[data-balloon-length]:after {
  white-space: normal;
}

[data-balloon-length='small']:after {
  width: 80px !important;
  max-width: 80px !important;
  text-align: center !important;
}

[data-balloon-length='medium']:after {
  width: 150px;
  text-align: center;
}

[data-balloon-length='large']:after {
  width: 216px;
  text-align: center;
}

[data-balloon-length='xlarge']:after {
  width: 90vw;
  text-align: center;
}

@media screen and (min-width: 768px) {
  [data-balloon-length='xlarge']:after {
    width: 350px;
  }
}

[data-balloon-length='fit']:after {
  width: 100%;
}

@media (max-width: 767px) {
  /* Right */
  [data-balloon-mobile-pos='right']:before,
  [data-balloon-mobile-pos='right']:after {
    top: 50%;
    left: 100%;
    bottom: auto;
    transform: translate(-10px, -50%);
  }
  [data-balloon-mobile-pos='right']:after {
    margin-left: 11px;
  }
  [data-balloon-mobile-pos='right']:before {
    background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='12' height='36'%3E%3Cpath fill='rgba(50, 55, 80, 1)' d='M12 2.658v32.004c0-6-12.002-11.627-12.002-16.002C-.002 14.285 12 8.594 12 2.658z'/%3E%3C/svg%3E");
    background-repeat: no-repeat;
    background-size: 100% auto;
    height: 18px;
    width: 6px;
    margin-bottom: 0;
    margin-left: 5px;
  }
  [data-balloon-mobile-pos='right']:hover:before,
  [data-balloon-mobile-pos='right'][data-balloon-visible]:before,
  [data-balloon-mobile-pos='right']:hover:after,
  [data-balloon-mobile-pos='right'][data-balloon-visible]:after {
    transform: translate(0px, -50%);
  }

  /* Down */
  [data-balloon-mobile-pos='down']:before,
  [data-balloon-mobile-pos='down']:after {
    left: 50%;
    top: 100%;
    bottom: auto;
    transform: translate(-50%, -10px);
  }

  [data-balloon-mobile-pos='down']:after {
    margin-top: 11px;
  }

  [data-balloon-mobile-pos='down']:before {
    background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='36' height='12'%3E%3Cpath fill='rgba(50, 55, 80, 1)' d='M33.342 12H1.338c6 0 11.627-12.002 16.002-12.002C21.715-.002 27.406 12 33.342 12z'/%3E%3C/svg%3E");
    background-repeat: no-repeat;
    background-size: 100% auto;
    height: 6px;
    width: 18px;
    margin-top: 5px;
    margin-bottom: 0;
  }

  [data-balloon-mobile-pos='down']:hover:before,
  [data-balloon-mobile-pos='down'][data-balloon-visible]:before,
  [data-balloon-mobile-pos='down']:hover:after,
  [data-balloon-mobile-pos='down'][data-balloon-visible]:after {
    transform: translate(-50%, 0px);
  }

  /* Left */
  [data-balloon-mobile-pos='left']:before,
  [data-balloon-mobile-pos='left']:after {
    top: 50%;
    left: auto;
    right: 100%;
    bottom: auto;
    transform: translate(10px, -50%);
  }
  [data-balloon-mobile-pos='left']:after {
    margin-right: 11px;
  }
  [data-balloon-mobile-pos='left']:before {
    background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='12' height='36'%3E%3Cpath fill='rgba(50, 55, 80, 1)' d='M0 33.342V1.338c0 6 12.002 11.627 12.002 16.002C12.002 21.715 0 27.406 0 33.342z'/%3E%3C/svg%3E");
    background-repeat: no-repeat;
    background-size: 100% auto;
    height: 18px;
    width: 6px;
    margin-right: 5px;
    margin-bottom: 0;
  }
  [data-balloon-mobile-pos='left']:hover:before,
  [data-balloon-mobile-pos='left'][data-balloon-visible]:before,
  [data-balloon-mobile-pos='left']:hover:after,
  [data-balloon-mobile-pos='left'][data-balloon-visible]:after {
    -webkit-transform: translate(0, -50%);
    -ms-transform: translate(0, -50%);
    transform: translate(0, -50%);
  }

  .payment-content-area .rdt_TableRow:last-of-type [data-balloon-mobile-pos='left']:before,
  .payment-content-area .rdt_TableRow:last-of-type [data-balloon-mobile-pos='left']:after {
    left: 50%;
    top: auto;
    bottom: 100%;
    transform: translate(-50%, 10px);
  }
  .payment-content-area .rdt_TableRow:last-of-type [data-balloon-mobile-pos='left']:before {
    background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='36' height='12'%3E%3Cpath fill='rgba(50, 55, 80, 1)' d='M2.658 0h32.004c-6 0-11.627 12.002-16.002 12.002C14.285 12.002 8.594 0 2.658 0z'/%3E%3C/svg%3E");
    background-repeat: no-repeat;
    background-size: 100% auto;
    height: 6px;
    width: 18px;
    content: '';
    margin-bottom: 5px;
  }
  .payment-content-area .rdt_TableRow:last-of-type [data-balloon-mobile-pos='left']:hover:before,
  .payment-content-area .rdt_TableRow:last-of-type [data-balloon-mobile-pos='left'][data-balloon-visible]:before,
  .payment-content-area .rdt_TableRow:last-of-type [data-balloon-mobile-pos='left']:hover:after,
  .payment-content-area .rdt_TableRow:last-of-type [data-balloon-mobile-pos='left'][data-balloon-visible]:after {
    -webkit-transform: translate(-50%, 0);
    -ms-transform: translate(-50%, 0);
    transform: translate(-50%, 0);
  }


  [data-balloon-mobile-length='medium']:after {
    width: 155px;
    text-align: center;
  }
}

/* End Balloon Tooltips */

/* Custom Error styling */
.Mui-error,
.has-error .Mui-error,
.has-error .select-error-msg {
  width: 100%;
  white-space: nowrap;
  padding: 0 var(--lp-space-xs);
  color: var(--lp-colors-error) !important;
  font-size: var(--lp-font-size-caption);
  font-family: var(--lp-fonts-caption);
  font-weight: var(--lp-font-weight-caption);
  letter-spacing: var(--lp-letter-spacing-caption);
  line-height: var(--lp-line-height-caption);
  margin-top: var(--lp-space-tiny);
}

.MuiFormControl-root .MuiFormHelperText-root {
  white-space: normal !important;
}
.content-wrap-form .Mui-error,
.content-wrap-form .has-error .Mui-error,
.content-wrap-form .has-error .select-error-msg {
  padding: 0;
}

/* Toatify */
.Toastify__toast {
  box-shadow: none;
  padding: var(--lp-space-xs) !important;
}
.Toastify__toast-body {
  margin: 0;
  padding: 0;
  font-size: var(--lp-space-xxs);
}
.Toastify__toast--success {
  color: var(--neutral-800);;
  box-shadow: 0px 4px 8px -2px rgba(0, 0, 0, 0.08);
  border: 1.5px solid var(--toastify-color-progress-success);
}
.Toastify__toast--success,
.Toastify__toast--error {
  border-radius: 4px;
  font-size: var(--lp-font-size-button-xxs);
  font-family: Geograph, san-serif !important;
  box-shadow: 0px 4px 8px -2px rgba(0, 0, 0, 0.08);
}
.Toastify__toast--success .Toastify__toast-icon svg {
  fill: var(--toastify-color-progress-success) !important;
  display: none !important;
}
.Toastify__toast--error .Toastify__progress-bar,
.Toastify__toast--success .Toastify__progress-bar {
  border-bottom-left-radius: 2px;
  border-bottom-right-radius: 2px;
}
.Toastify__toast--success .Toastify__progress-bar {
  background: var(--toastify-color-progress-success);
}
.Toastify__close-button--light {
  opacity: 1;
  margin-top: 4px;
}
.Toastify__toast--success .Toastify__close-button svg {
  fill: var(--neutral-600);
}
.Toastify__toast--success .Toastify__toast-icon {
  width: 24px;
  height: 24px;
  flex-shrink: 0;
  display: flex;
  border-radius: 50%;
  align-items: center;
  justify-content: center;
  background-color: var(--toastify-color-progress-success);
}
.Toastify__toast--success .Toastify__toast-icon::before {
  top: 50%;
  left: 50%;
  content: "";
  width: 24px;
  height: 24px;
  position: absolute;
  transform: translate(-50%, -50%);
  background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='24' height='24' fill='%23191e32'%3E%3Cpath d='M17.707 9.707a1 1 0 0 0-1.414-1.414L10 14.586l-2.293-2.293a1 1 0 0 0-1.414 1.414l3 3a1 1 0 0 0 1.414 0l7-7z'/%3E%3C/svg%3E");
}

.Toastify__toast--error {
  color: var(--toastify-color-progress-error);
  background-color: var(--toastify-color-progress-error3);
  border: 1.5px solid var(--toastify-color-progress-error2);
}
.Toastify__toast--error .Toastify__close-button svg,
.Toastify__toast--error .Toastify__toast-icon svg {
  fill: var(--toastify-color-progress-error2) !important
}
.Toastify__toast--error .Toastify__progress-bar {
  background: var(--toastify-color-progress-error2);
}

.modal-content > .payout .Mui-error {
  padding-left: 0 !important;
}

.no-cursor {
  cursor: none !important;
  pointer-events: none !important;
}

/* Popover Menu */
.react-tiny-popover-container {
  z-index: 3;
}
.t-action-dropdown {
  width: 204px;
  max-width: 204px;
  border-radius: 5px;
  background-color: #fff;
  box-shadow: 0px 8px 24px -4px rgba(0, 0, 0, 0.125);
}
.t-action-dropdown-body {
  width: 100%;
  padding: 100%;
  border-radius: 5px;
  padding: var(--lp-space-xxxs);
}
.t-action-dropdown-body button {
  outline: none;
  padding: 6px 0;
  font-size: 14px;
  font-weight: 500;
}
.t-action-dropdown-body button:hover {
  color: rgba(var(--lp-colors-primary), 1);
}
.t-action-dropdown-body button svg {
  top: 1px;
  position: relative;
}

@media (max-width: 767px) {
  body {
    overflow-x: hidden !important;
  }
	.sm\:flex-row {
		flex-direction: row !important;
	}
	.sm\:flex-col {
		flex-direction: column !important;
	}
  .sm\:align-start {
    align-items: flex-start !important;
  }
  .sm\:align-end,
  .sm\:lp-align-end {
    align-items: flex-end !important;
  }
  .sm\:justify-end {
    justify-content: flex-end !important;
  }
  .sm\:lp-full {
    width: 100% !important;
    flex: 0 0 100% !important;
    max-width: 100% !important;
  }
  .sm\:mb-12 {
    margin-bottom: 12px !important;
  }
  .sm\:mb-24 {
    margin-bottom: 24px !important;
  }
  .sm\:mt-16 {
    margin-top: 16px !important;
  }
  .sm\:mt-24 {
    margin-top: 24px !important;
  }
  .sm\:text-left {
    text-align: left;
  }
  .sm\:pr-0 {
    padding-right: 0 !important;
  }
  .sm\:ml-0 {
    margin-left: 0 !important;
  }
  .MuiInputBase-root.MuiInputBase-adornedEnd .MuiInputBase-input {
    text-overflow: ellipsis;
    padding-right: var(--lp-space-sm) !important;
  }

  .react-tiny-popover-container {
    margin-left: 175px;
  }
}

@media (max-width: 1023px) {
	.md\:flex-col {
		flex-direction: column !important;
    -ms-box-orient: vertical;
    box-orient: vertical;
	}
	.md\:flex-row {
		flex-direction: row !important;
	}
  .md\:align-center {
    align-items: center !important;
  }
  .md\:lp-full {
    width: 100% !important;
    flex: 0 0 100% !important;
    max-width: 100% !important;
  }
  .md\:ml-0 {
    margin-left: 0 !important;
  }
  .md\:mt-24 {
    margin-top: 24px !important;
  }
  .md\:mb-16 {
    margin-bottom: 16px !important;
  }
}
